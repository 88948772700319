<template>
    <div class="pager-outer-wrap">
        <div class="pager-wrap">
            <ul class="pager prev-nav" v-if="current_page > 1 && total_page > 1">
                <li>
                    <div @click="movePage(1)" >
                        <img src="../../assets/images/paginator/pager-first.png">
                    </div>
                </li>
                <li>

                    <div @click="movePage(current_page-1)" >
                        <img src="../../assets/images/paginator/pager-previous.png">
                    </div>
                </li>
            </ul>
            <ul class="pager pc-tablet">
                <li :class="{select: Number(value) === Number(current_page)}" v-for="(value, index) in pcPageList" :key="index">
                    <div @click="movePage(value)">{{value}}</div>
                </li>
            </ul>
            <ul class="pager only-s-mobile">
                <li :class="{select: Number(value) === Number(current_page)}" v-for="(value, index) in mobilePageList" :key="index">
                    <div @click="movePage(value)">{{value}}</div>
                </li>
            </ul>
            <ul class="pager next-nav" v-if="total_page > current_page">
                <li>

                    <div @click="movePage(current_page+1)" >
                        <img src="../../assets/images/paginator/pager-next.png">
                    </div>
                </li>
                <li>

                    <div @click="movePage(total_page)">
                        <img src="../../assets/images/paginator/pager-last.png">
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            current_page: Number,
            total_page: Number,
        },
        data() {
            return {
                pcPageList: [],
                mobilePageList: []
            }
        },
        methods: {
            updatePageList () {
                let pcStartPage = this.current_page;
                let pcLastPage = 7;
                let mobileStartPage = this.current_page;
                let mobileLastPage = 5;
                if (this.total_page <= 7) {
                    pcStartPage = 1
                }
                if (this.total_page <= 5) {
                    mobileStartPage = 1
                }
                if (this.current_page > 3 && this.total_page > 7) {
                    pcStartPage = this.current_page - 3
                    pcLastPage = this.current_page + 3
                    if (pcLastPage >= this.total_page) {
                        pcLastPage = this.total_page
                    }
                    if (pcLastPage - pcStartPage < 7) {
                        pcStartPage = pcLastPage - 6
                    }
                }

                if (pcLastPage <= 7) {
                    pcStartPage = 1
                }
                if (this.total_page <= 7) {
                    pcLastPage = this.total_page
                }

                if (this.current_page > 2 && this.total_page > 5) {
                    mobileStartPage = this.current_page - 2
                    mobileLastPage = this.current_page + 2
                    if (mobileLastPage >= this.total_page) {
                        mobileLastPage = this.total_page
                    }
                    if (mobileLastPage - mobileStartPage < 5) {
                        mobileStartPage = mobileLastPage - 4
                    }
                }
                if (mobileLastPage <= 5) {
                    mobileStartPage = 1
                }
                if (this.total_page <= 5) {
                    mobileLastPage = this.total_page
                }
                for (let i = pcStartPage; i <= pcLastPage; i++) {
                    this.pcPageList.push(i)
                }
                for (let i = mobileStartPage; i <= mobileLastPage; i++) {
                    this.mobilePageList.push(i)
                }
            },
            movePage(page) {
                this.$emit('movePage', page)
            },
        },
        created() {
            this.updatePageList()
        },
        mounted() {
        }
    }
</script>
